//* Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFi2kB252WpUhQkKy7VtfIJZOLvXNyuKA",
  authDomain: "tystoken.firebaseapp.com",
  projectId: "tystoken",
  storageBucket: "tystoken.appspot.com",
  messagingSenderId: "487063467667",
  appId: "1:487063467667:web:48bc1e804d687f4d2e7f66",
  measurementId: "G-68T4JGT6ZE",
};

//* Initialize Firebase
let firebase_app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
